/**
***    monosus からの移植    */

// initialize
//
$(function () {
  pagetop()
  page_anchor()
  tabModule()
})



/** タブ */
const tabModule = function(){
  if( $('.js-tab-module' ).length ){
    $('.js-tab-module').each(function(){
      var $thisTabNav = $(this).find('.js-tab-nav .item a');
      var $thisTabcontent = $(this).find('.js-tab-content');

      $thisTabNav.on('click', function(e){
        e.preventDefault();
        var $this = $(this);
        var $target = $($this.attr('href'));
        if(!$this.hasClass('is-active')){
          $thisTabNav.removeClass('is-active');
          $thisTabcontent.hide();
          $this.addClass('is-active');
          $target.stop().fadeIn(200);
        }
      });

      $(this).find('.item:nth-child(1) a').trigger('click');
    });
  }
}

/** ページ内リンク */
const page_anchor = function(){
  $('.js-anchor').on('click', function(e){

    const href = $(this).attr('href')
    const header = $('header').height()
    let position = $(href).offset().top

    if( href.slice( 0, 1 ) != '#' ){
      e.preventDefault()
    }

    if( ! window.matchMedia( "(min-width: 813px)" ).matches ){
      position = $(href).offset().top - header
    }

    $('body, html').animate({ scrollTop: position }, 400, 'swing')
  })
}


/**
***   kotobuki  */

// pagetop
const pagetop = function () {
  $('.js-pagetop').on('click', function(){
    $('body, html').animate({ scrollTop: 0 }, 400, 'swing')
  })
}
